<template>
  <div class="gzIndex1">
    <div class="gzIndex1-container">
      <div class="gz-pub-cell gz-cell1">
        <div class="gz-cell-name">PV-H701</div>
        <div class="gz-cell-num"
         @click="toCompon(2, 'VP15', 'DB1S', 'VP15_GZQ','阀门开度--旋风床离心机进料回流阀')"
          @dblclick="Cclick(infoList.DB1S.VP15,'VP15', 'DB1S', 'VP15_GZQ')"
        >
          {{infoList.DB1S.VP15}}%
        </div>
      </div>
      <div class="gz-pub-cell gz-cell2"
      @click="toCompon(2, 'PT13', 'DB1S', 'PT13_GZQ','旋风床离心机进料压力')"
       @dblclick="Cclick(infoList.DB1S.PT13,'PT13', 'DB1S', 'PT13_GZQ')"
      >
        <div class="gz-cell-num">{{infoList.DB1S.PT13}} Mpa</div>
        <div class="gz-cell-name">PI-H701</div>
      </div>
      <!-- <div class="bot-grid-3-1 flex align-cen">
        <div class="child-databtn">X</div>
        <div class="child-databtn">A</div>
      </div> -->
      <div class="gz-pub-cell gz-cell3">
        <div class="gz-cell-name">JIC-H701A</div>
        <div class="gz-cell-num"
         @click="toCompon(2, 'VP01', 'DB1S', 'VP01_GZQ','阀门开度--旋风床1#离心机进料阀')"
          @dblclick="Cclick(infoList.DB1S.VP01,'VP01', 'DB1S', 'VP01_GZQ')"
        >{{infoList.DB1S.VP01}}%</div>
      </div>
      <div class="bot-grid-3-2 flex align-cen">
        <div class="child-databtn" 
         :style="infoList.DB1M__p__XK01  | bgFillter" 
         @click="infoList.DB1M__p__XK01 ? toDetail(1,'XK01',`DB1M__p__XK01`,'','XK01运行方式') : ''"
        >
          X
          </div>
        <div class="child-databtn"
        :style="infoList.DB1M__p__MAN01 | bgFillter1" 
        @click="infoList.DB1M__p__MAN01 ? toCompon( 0,'AV',`DB1M__p__MAN01`,'MAN01_AV','1#离心机进料阀手操器'): ''"
        >A</div>
      </div>
      <div class="gz-pub-cell gz-cell4">
        <div class="gz-cell-name">II-H701A</div>
        <div class="gz-cell-num"
         @click="toCompon(2, 'NI01', 'DB1S', 'NI01_GZQ','旋风床1#离心机进料功率')"
       @dblclick="Cclick(infoList.DB1S.NI01,'NI01', 'DB1S', 'NI01_GZQ')"
        >
          {{infoList.DB1S.NI01}} KW
          </div>
      </div>
      <div class="gz-pub-cell gz-cell5">
        <div class="gz-cell-name">CY-1H</div>
      </div>
      <div class="gz-pub-cell gz-cell6">
        <div class="gz-cell-name">HV-H701</div>
      </div>
      <div class="gz-pub-cell gz-cell7">
        <div class="gz-cell-name">BL-3H</div>
      </div>
      <div class="gz-pub-cell gz-cell8">
        <div class="gz-cell-name">FI-H701</div>
        <div class="gz-cell-num"
         @click="toCompon(2, 'FT11', 'DB1S', 'FT11_GZQ','旋风床引凤流量')"
       @dblclick="Cclick(infoList.DB1S.FT11,'FT11', 'DB1S', 'FT11_GZQ')"
        >
          {{infoList.DB1S.FT11}} m³/h
          </div>
      </div>
      <div class="gz-pub-cell gz-cell9">
        <div class="gz-cell-name">TI-H705</div>
        <div class="gz-cell-num"
        @click="toCompon(2, 'TE14', 'DB1S', 'TE14_GZQ','旋风床出口温度')"
       @dblclick="Cclick(infoList.DB1S.TE14,'TE14', 'DB1S', 'TE14_GZQ')"
        >
          
          {{infoList.DB1S.TE14}} ℃</div>
      </div>
      <div class="gz-pub-cell gz-cell10">
        <div class="gz-cell-name">II-H701B</div>
        <div class="gz-cell-num"
       @click="toCompon(2, 'NI02', 'DB1S', 'NI02_GZQ','旋风床2#离心机进料功率')"
       @dblclick="Cclick(infoList.DB1S.NI02,'NI02', 'DB1S', 'NI02_GZQ')"
        >
          {{infoList.DB1S.NI02}}
           KW</div>
      </div>
      <div class="gz-pub-cell gz-cell11">
        <div class="gz-cell-name">JIC-H701B</div>
        <div class="gz-cell-num"
        @click="toCompon(2, 'VP02', 'DB1S', 'VP02_GZQ','阀门开度--旋风床2#离心机进料阀')"
       @dblclick="Cclick(infoList.DB1S.VP02,'VP02', 'DB1S', 'VP02_GZQ')"
        >
           {{infoList.DB1S.VP02}}%
           </div>
      </div>
      <div class="gz-pub-cell gz-cell12">
        <div class="gz-cell-name">TK-2G</div>
      </div>
      <div class="bot-grid-3-3 flex align-cen">
        <div class="child-databtn" 
         :style="infoList.DB1M__p__XK18  | bgFillter" 
         @click="infoList.DB1M__p__XK18 ? toDetail(1,'XK18',`DB1M__p__XK18`,'','XK18运行方式') : ''"
        >
          X
          </div>
       <div class="child-databtn" 
         :style="infoList.DB1M__p__XK02  | bgFillter" 
         @click="infoList.DB1M__p__XK02 ? toDetail(1,'XK02',`DB1M__p__XK02`,'','XK02运行方式') : ''"
        >
          X
          </div>
      </div>
      <div class="bot-grid-3-4 flex align-cen">
        <div class="child-databtn"
          :style="infoList.DB1M__p__XK18  | bgFillter" 
         @click="infoList.DB1M__p__XK18 ? toDetail(1,'XK18',`DB1M__p__XK18`,'','XK18运行方式') : ''"
        >X</div>
        <div class="child-databtn"
         :style="infoList.DB1M__p__MAN02 | bgFillter1" 
        @click="infoList.DB1M__p__MAN02 ? toCompon( 0,'AV',`DB1M__p__MAN02`,'MAN02_AV','2#离心机进料阀手操器'): ''"
        >A</div>
      </div>
      <div class="gz-pub-cell gz-cell13">
        <div class="gz-cell-name">TK-4G</div>
      </div>
      <div class="gz-pub-cell gz-cell14">
        <div class="gz-cell-name">TI-H704</div>
      <div class="gz-cell-num"
        @click="toCompon(2, 'TE13', 'DB1S', 'TE13_GZQ','旋风床入口温度')"
       @dblclick="Cclick(infoList.DB1S.TE13,'TE13', 'DB1S', 'TE13_GZQ')"
        >
          
          {{infoList.DB1S.TE13}} ℃</div>
      </div>
      <div class="gz-pub-cell gz-cell15">
        <div class="gz-cell-name">TI-H706</div>
        <div class="gz-cell-num"
        @click="toCompon(2, 'TE12', 'DB1S', 'TE12_GZQ','气流管中部热风温度')"
       @dblclick="Cclick(infoList.DB1S.TE12,'TE12', 'DB1S', 'TE12_GZQ')"
        >{{infoList.DB1S.TE12}} ℃</div>
      </div>
      <div class="gz-pub-cell gz-cell16">
        <div class="gz-cell-name">绞龙</div>
      </div>
      <div class="gz-pub-cell gz-cell17">
        <div class="gz-cell-name">蒸汽</div>
      </div>
      <div class="gz-pub-cell gz-cell18">
        <div class="gz-cell-name">PI-SC01</div>
        <div class="gz-cell-num"
          @click="toCompon(2, 'PT01', 'DB1S', 'PT01_GZQ','蒸汽总管压力')"
       @dblclick="Cclick(infoList.DB1S.PT01,'PT01', 'DB1S', 'PT01_GZQ')"
        >
          {{infoList.DB1S.PT01}} KG/CM2</div>
      </div>
      <div class="gz-pub-cell gz-cell19">
        <div class="gz-cell-num"
         @click="toCompon(2, 'VP06', 'DB1S', 'VP06_GZQ','阀门开度--旋风床蒸汽阀')"
       @dblclick="Cclick(infoList.DB1S.VP06,'VP06', 'DB1S', 'VP06_GZQ')"
        >
           {{infoList.DB1S.VP06}}%</div>
        <div class="gz-cell-name">TV-H704</div>
      </div>
      <div class="gz-pub-cell gz-cell20">
        <div class="gz-cell-name">PI-H702</div>
        <div class="gz-cell-num"
        @click="toCompon(2, 'PT14', 'DB1S', 'PT14_GZQ','旋风床阀后蒸汽压力')"
       @dblclick="Cclick(infoList.DB1S.PT14,'PT14', 'DB1S', 'PT14_GZQ')"
        >{{infoList.DB1S.PT14}} Mpa</div>
      </div>
      <div class="bot-grid-3-5 flex align-cen">
        <div class="child-databtn"
            :style="infoList.DB1M__p__XK16  | bgFillter" 
         @click="infoList.DB1M__p__XK16 ? toDetail(1,'XK16',`DB1M__p__XK16`,'','XK16运行方式') : ''"
        >X</div>
        <div class="child-databtn"
          :style="infoList.DB1M__p__XK06  | bgFillter" 
         @click="infoList.DB1M__p__XK06 ? toDetail(1,'XK06',`DB1M__p__XK06`,'','XK06运行方式') : ''"
        >X</div>
        <div class="child-databtn"
        :style="infoList.DB1M__p__MAN06 | bgFillter1" 
        @click="infoList.DB1M__p__MAN06 ? toCompon( 0,'AV',`DB1M__p__MAN06`,'MAN06_AV','混料风量手操器'): ''"
        >A</div>
      </div>
      <div class="gz-pub-cell gz-cell21">
        <div class="gz-cell-name">PI-H705</div>
        <div class="gz-cell-num"
          @click="toCompon(2, 'PT15', 'DB1S', 'PT15_GZQ','旋风床气流管压力')"
       @dblclick="Cclick(infoList.DB1S.PT15,'PT15', 'DB1S', 'PT15_GZQ')"
        >
          {{infoList.DB1S.PT15}} Mpa</div>
      </div>
      <div class="gz-pub-cell gz-cell22">
        <div class="gz-cell-name">FI-H702</div>
        <div class="gz-cell-num"
         @click="toCompon(2, 'FT10', 'DB1S', 'FT10_GZQ','旋风床鼓风流量')"
       @dblclick="Cclick(infoList.DB1S.FT10,'FT10', 'DB1S', 'FT10_GZQ')"
        >
          {{infoList.DB1S.FT10}} m³/h</div>
      </div>
      <div class="gz-pub-cell gz-cell23">
        <div class="gz-cell-name">鼓风机</div>
      </div>
      <div class="gz-pub-cell gz-cell24">
        <div class="gz-cell-name">BL-2H</div>
      </div>
      <div class="bot-grid-3-6 flex align-cen">
        <div class="child-databtn"
        :style="infoList.DB1M__p__XK13  | bgFillter" 
         @click="infoList.DB1M__p__XK13 ? toDetail(1,'XK13',`DB1M__p__XK13`,'','XK13运行方式') : ''"
        >X</div>
        <div class="child-databtn"
         :style="infoList.DB1M__p__MAN13 | bgFillter1" 
        @click="infoList.DB1M__p__MAN13 ? toCompon( 0,'AV',`DB1M__p__MAN13`,'MAN13_AV','床内负压手操器'): ''"
        >A</div>
      </div>
      <div class="gz-pub-cell gz-cell25">
        <div class="gz-cell-name">HE-1HA/B</div>
      </div>
      <div class="gz-pub-cell gz-cell26">
        <div class="gz-cell-name">换热器</div>
      </div>
      <div class="gz-pub-cell gz-cell27">
        <div class="gz-cell-name">TI-H702</div>
        <div class="gz-cell-num"
         @click="toCompon(2, 'TE10', 'DB1S', 'TE10_GZQ','换热后热风温度')"
       @dblclick="Cclick(infoList.DB1S.TE10,'TE10', 'DB1S', 'TE10_GZQ')"
        >
          {{infoList.DB1S.TE10}} ℃</div>
      </div>
      <div class="gz-pub-cell gz-cell28">
        <div class="gz-cell-name">TI-H703</div>
          <div class="gz-cell-num"
         @click="toCompon(2, 'TE11', 'DB1S', 'TE11_GZQ','气流管底部热风温度')"
       @dblclick="Cclick(infoList.DB1S.TE11,'TE11', 'DB1S', 'TE11_GZQ')"
        >
          {{infoList.DB1S.TE11}} ℃</div>
      </div>
      <div class="gz-pub-cell gz-cell29">
        <div class="gz-cell-name">成品料仓</div>
      </div>
      <img
        src="../../../assets/images/gzznimg2.png"
        alt=""
        class="gz-cell-30"
      />
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
<script>
import Historical from "@/components/Historical.vue"; //历史趋势
import Manual from "@/components/Manual.vue"; //手操器组件
import index from "../GzIndex/_index/index.js";
export default {
  name: "Jlyxoj",
  components: {
    Historical,
    Manual
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data() {
    return {
    chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
   mixins: [index],
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
  },
  methods: {
    //
    openTip() {
      this.$message.error("请返回通讯总览页面执行操作");
    },
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  filters: {
    //        颜色过滤器
    bgFillter(val) {
     		if (val && (val['RM'] == 1 || val['RM'] == 2)) {
					return 'background:#2AFC30';
				} else{
        return 'background:red';
          }
    },
      bgFillter1(val) {
     		if (val && val['RM'] == 1) {
					return 'background:#2AFC30';
				} else{
        return 'background:red';
          }
    },
    // 文字过滤器
    textFillter: (val) => {
      if (val) {
        return "参与";
      } else {
        return "不参与";
      }
    },
    text1Fillter: (val) => {
      if (val) {
        return "自动";
      } else {
        return "手动";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gzIndex1 {
  .gzIndex1-container {
    width: 81vw;
    height: 73vh;
    margin: 0 auto;
    background: url("~@/assets/images/gzznimg1.png") no-repeat 0 0;
    background-size: 100% 100%;
    position: relative;
    margin-top: 5vh;
    .gz-pub-cell {
      position: absolute;
      color: #d9feff;
      line-height: 2vh;
      .gz-cell-name {
        font-size: 0.9vw;
      }
      .gz-cell-num {
        font-size: 0.8vw;
        cursor: pointer;
      }
    }
    .gz-cell1 {
      left: 4vw;
      top: 1.5vh;
    }
    .gz-cell2 {
      left: 13vw;
      top: 4.5vh;
    }
    .gz-cell3 {
      left: 20vw;
      top: 3vh;
    }
    .gz-cell4 {
      left: 33vw;
      top: 3vh;
    }
    .gz-cell5 {
      font-size: 1vw;
      left: 57vw;
      top: 3vh;
    }
    .gz-cell6 {
      left: 67.2vw;
      top: 3vh;
    }
    .gz-cell7 {
      font-size: 1vw;
      left: 72vw;
      top: 11.5vh;
    }
    .gz-cell8 {
      left: 77vw;
      top: 8.5vh;
      .gz-cell-num {
        width: 10vw;
      }
    }
    .gz-cell9 {
      left: 44.5vw;
      top: 11.5vh;
    }
    .gz-cell10 {
      left: 26vw;
      top: 13.5vh;
    }
    .gz-cell11 {
      left: 16vw;
      top: 20.5vh;
    }
    .gz-cell12 {
      font-size: 1vw;
      left: 1vw;
      top: 23vh;
    }
    .gz-cell13 {
      font-size: 1vw;
      left: 1vw;
      top: 40vh;
    }
    .gz-cell14 {
      left: 47vw;
      top: 30.5vh;
    }
    .gz-cell15 {
      left: 39vw;
      top: 40.5vh;
    }
    .gz-cell16 {
      font-size: 1vw;
      left: 23vw;
      top: 45.5vh;
    }
    .gz-cell17 {
      font-size: 1vw;
      left: 2vw;
      top: 52.5vh;
    }
    .gz-cell18 {
      left: 5vw;
      top: 48.5vh;
    }
    .gz-cell19 {
      left: 11vw;
      top: 55.5vh;
    }
    .gz-cell20 {
      left: 20vw;
      top: 54.5vh;
    }
    .gz-cell21 {
      left: 39vw;
      top: 55.5vh;
    }
    .gz-cell22 {
      left: 0vw;
      top: 62.5vh;
    }
    .gz-cell23 {
      left: 7vw;
      top: 61vh;
    }
    .gz-cell24 {
      font-size: 1vw;
      left: 11vw;
      top: 62.5vh;
    }
    .gz-cell25 {
      font-size: 1vw;
      left: 21vw;
      top: 60.5vh;
    }
    .gz-cell26 {
      font-size: 1vw;
      left: 21vw;
      top: 67vh;
    }
    .gz-cell27 {
      left: 29vw;
      top: 60.5vh;
    }
    .gz-cell28 {
      left: 39vw;
      top: 62.5vh;
    }
    .gz-cell29 {
      left: 68vw;
      top: 65vh;
    }
    .gz-cell-30 {
      position: absolute;
      height: 2vw;
      width: 2vw;
      left: 26vw;
      top: 29vh;
    }
    .bot-grid-3-1 {
      position: absolute;
      left: 14vw;
      top: 8.5vh;
    }
    .bot-grid-3-2 {
      position: absolute;
      left: 27vw;
      top: 6.5vh;
    }
    .bot-grid-3-4 {
      position: absolute;
      left: 21vw;
      top: 16.5vh;
    }
    .bot-grid-3-5 {
      position: absolute;
      left: 28vw;
      top: 55.5vh;
    }
    .bot-grid-3-6 {
      position: absolute;
      left: 11vw;
      top: 65.5vh;
    }
    .bot-grid-3-3 {
      position: absolute;
      left: 6vw;
      top: 16.5vh;
    }
    .child-databtn {
      background: #2afc30;
      color: #fff;
      width: 1vw;
      height: 2vh;
      line-height: 2vh;
      text-align: center;
      box-sizing: border-box;
      border: 1px solid #fdf061;
      font-size: 0.8vw;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}
</style>